angular.module('fingerink')
    .service('changeUserModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function (id) {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                keyboard: false,
                controller: 'changeUserModalCtrl',
                templateUrl: 'web/main/team/changeUserModal.tpl.html',
                controllerAs: 'controller',
                resolve:{
                    user : userService=>userService.getUsuarios({id:id})
                }
            });
            return this.modalInstance;
        };
    })
    .controller('changeUserModalCtrl', function mostrarCtrl($q, $scope, $uibModalInstance, userService, swalService,user) {
        var that = this;
        that.user = user.data[0];
        that.cancel = () => $uibModalInstance.close();
        that.change = () => {
            that.loading = true;

            
             
            userService.updateUsuario(that.user).then(r => {
                that.loading = false;
                swalService.basicSwal('Correcto!', 'Se han cambiado los datos de acceso correctamente', 'success');
                $uibModalInstance.close(r.data);
                fingerinkSession.renoveToken();
            }, e => {
                swalService.basicSwal('Error', 'Ha habido un problema al cambiar los datos de acceso', 'error');
                that.loading = false;
                fingerinkSession.renoveToken();
            });
            
        };
    });
